import React from "react";
import MUIButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Button.scss";

const Button = ({
  label,
  width,
  margin,
  loading = false,
  disabled,
  ...inputProps
}) => {
  return (
    <MUIButton
      className="CustomButton"
      disabled={loading || disabled}
      style={{ width: width, margin: margin }}
      {...inputProps}
    >
      {!loading ? (
        label
      ) : (
        <CircularProgress className="CustomButton__Progressbar" />
      )}
    </MUIButton>
  );
};

export default Button;
