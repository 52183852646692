import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Tracker from "./utils/analytics/Tracker";

import ServicesContextProvider from "./contexts/servicesContext";
import ScrollToTop from "./components/helpers/ScrollToTop";
import { Home } from "./components/Home";
import { Reservation } from "./components/Reservation";
import { Fleet } from "./components/Fleet";
import { About } from "./components/About";
import { Footer } from "./components/Footer";
import { PrivacyPolicy } from "./components/Info";

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const Routes = () => (
  <ThemeProvider theme={materialTheme}>
    <ServicesContextProvider>
      <Router>
        <Tracker>
          <ScrollToTop />
          <Switch>
            <Route path="/free-quote" exact component={Reservation} />
            <Route path="/our-services" exact component={Fleet} />
            <Route path="/about" exact component={About} />

            <Route path="/privacy-policy" exact component={PrivacyPolicy} />

            <Route path="/" component={Home} />
          </Switch>
          <Footer />
        </Tracker>
      </Router>
    </ServicesContextProvider>
  </ThemeProvider>
);

export default Routes;
