import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import { initializeGA } from "./utils/analytics";

import "./index.css";

if (process.env.NODE_ENV === "production") {
  initializeGA();
}

ReactDOM.render(<Routes />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
