import React, { useContext } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { ServiceItem } from "./ServiceItem";
import { ServicesContext } from "../../contexts/servicesContext";

import { FleetItem } from "../Fleet/FleetItem";
import allCars from "../Fleet/allCars";

import "./Services.scss";

import nightPartiesImg from "../../assets/images/Services/night-parties.jpg";
import promPartiesImg from "../../assets/images/Services/prom-parties.png";
import airportTransferImg from "../../assets/images/Services/airport-transfer.png";

const Services = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const servicesContext = useContext(ServicesContext);

  return (
    <div>
      {!isMobile ? (
        <SwipeableDrawer
          anchor="right"
          transitionDuration={400}
          open={servicesContext.open}
          onClose={servicesContext.setClose}
          onOpen={servicesContext.setOpen}
        >
          <div className="ServicesDrawer">
            <div className="ServicesDrawer__Wrapper">
              <IconButton
                className="ServicesDrawer__Wrapper__CloseButton"
                onClick={servicesContext.setClose}
              >
                <CloseIcon className="ServicesDrawer__Wrapper__CloseButton__Icon" />
              </IconButton>
              <div className="ServicesDrawer__Wrapper__Content">
                <h2 className="ServicesDrawer__Wrapper__Content__Title">
                  Additional Services
                </h2>
                <div className="ServicesDrawer__Wrapper__Content__Features">
                  <ServiceItem
                    icon={nightPartiesImg}
                    title="Cargo Insurance"
                    text="The right coverage against physical damage or loss of goods during shipping on any mode of transport."
                  />
                  <ServiceItem
                    icon={airportTransferImg}
                    title="Customs Brokerage"
                    text="By getting to know your business on a professional level and you personally, our timely and high quality Customs Brokerage service can implement the best possible solution for you."
                  />
                  <ServiceItem
                    icon={promPartiesImg}
                    title="Smart Packaging Services"
                    text="Smart packaging is a key factor of cost efficiency."
                  />
                  <ServiceItem
                    icon={promPartiesImg}
                    title="Warehousing Services"
                    text="Our global and extensive network let us to handle your supply chain providing domestic, regional/international and multi-country consolidation services."
                  />
                  <ServiceItem
                    icon={promPartiesImg}
                    title="Pet Moving"
                    text="Your pet will fly safely with us."
                  />
                </div>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      ) : (
        <>
          <h2 className="Services__Title" style={{margin: "60px 0 20px 0"}}>Our Services</h2>
          <div className="Fleet__Wrapper" id="fleet-items">
            {allCars.map((car, index) => (
              <FleetItem
                key={car.name}
                name={car.name}
                images={car.images}
                description={car.description}
                reverse={index % 2 === 1}
              />
            ))}
          </div>
          <div id="services" className="Services">
            <h2 className="Services__Title">Additional Services</h2>
            <div className="Services__Content">
              <div className="Services__Content__Features">
                <ServiceItem
                  icon={nightPartiesImg}
                  title="Cargo Insurance"
                  text="The right coverage against physical damage or loss of goods during shipping on any mode of transport."
                />
                <ServiceItem
                  icon={airportTransferImg}
                  title="Customs Brokerage"
                  text="By getting to know your business on a professional level and you personally, our timely and high quality Customs Brokerage service can implement the best possible solution for you."
                />
                <ServiceItem
                  icon={promPartiesImg}
                  title="Smart Packaging Services"
                  text="Smart packaging is a key factor of cost efficiency."
                />
                <ServiceItem
                  icon={promPartiesImg}
                  title="Warehousing Services"
                  text="Our global and extensive network let us to handle your supply chain providing domestic, regional/international and multi-country consolidation services."
                />
                <ServiceItem
                  icon={promPartiesImg}
                  title="Pet Moving"
                  text="Your pet will fly safely with us."
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Services;
