import React from "react";

import "./ServiceItem.scss";

const ServiceItem = ({ icon, title, text }) => {
  return (
    <div className="ServiceItem">
      {/* {icon && <img className="ServiceItem__Icon" src={icon} alt={title} />} */}
      <div className="ServiceItem__Texts">
        <h3 className="ServiceItem__Texts__Title">{title}</h3>
        <p className="ServiceItem__Texts__Text">{text}</p>
      </div>
    </div>
  );
};

export default ServiceItem;
