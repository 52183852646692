import React from "react";
import { Helmet } from "react-helmet";

import { Header } from "../Header";

import aboutImg from "../../assets/images/About/about.jpg";

import "./About.scss";

const About = () => {
  return (
    <div className="About" id="about">
      <Helmet>
        <title>About Us | Global Cargo Bridge</title>
        <meta
          name="description"
          content="Bridging The World Together"
        />
      </Helmet>
      <Header backgroundColor="#000" menuColor="#000" filter={false} />
      <div className="About__Wrapper">
        <div className="About__Wrapper__Content">
          <h2 className="About__Wrapper__Content__Title">Our Mission</h2>
          <div className="About__Wrapper__Content__Info">
            <img
              className="About__Wrapper__Content__Info__Image"
              src={aboutImg}
              alt="car"
            />
            <p className="About__Wrapper__Content__Info__Text">
              Our mission is to provide innovative, cost and time effective logistics 
              solutions that help businesses grow.
              <br /> <br />
              Our team is striving to solve any kind of complex challenges of our 
              customers, always satisfying and standing behind them.
              <br /> <br />
              Having large network of logistics providers around the globe, 
              we are working hard to provide most effective, reliable, 
              specialized and in time logistics solutions for your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
