import React from "react";
import { Link as ScrollLink } from "react-scroll";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { phone, phoneTel, email } from "../../constants/info";
import { Header } from "../Header";
import { Arrow } from "../../atoms";

import "./LandingPage.scss";

const LandingPage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="LandingPage" id="landing-page">
      <Header noHeader={true} drawerOpen={false} />

      <div className="LandingPage__Top">
        <p className="LandingPage__Top__Text">
          Bridging The World Together
        </p>
        <div style={{ display: "flex" }}>
          <a className="LandingPage__Top__Group" href={"tel:" + phoneTel}>
            <PhoneIcon className="LandingPage__Top__Group__Icon" />
            {!isMobile && phone}
          </a>
          <a className="LandingPage__Top__Group" href={"mailto:" + email}>
            <EmailIcon className="LandingPage__Top__Group__Icon" />
            {!isMobile && email}
          </a>
        </div>
      </div>

      <div className="LandingPage__Wrapper">
        <h1 className="LandingPage__Wrapper__Title">
          Welcome to Global Cargo Bridge
        </h1>
        <h2 className="LandingPage__Wrapper__Subtitle">
        Logistics is an inseparable part of our everyday lives and in today’s world it plays a vital role in all aspects of global trade.
        <div className="LandingPage__Wrapper__Empty"></div>
        Global Cargo Bridge is created to support your business.
        <div className="LandingPage__Wrapper__Empty"></div>
        Analyzing each of our customers supply chain individually, we are trying to design logistics solutions that best fits their needs.
        </h2>
        <a className="LandingPage__Wrapper__Button" href={"tel:" + phoneTel}>
          {!isMobile ? (
            <>Contact Us {phone}</>
          ) : (
            <>
              Contact Us <br /> {phone}
            </>
          )}
        </a>
      </div>

      {isMobile && (
        <div className="LandingPage__Arrow">
          <ScrollLink to="services" duration={500} smooth={true}>
            <Arrow />
          </ScrollLink>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
