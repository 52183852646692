// eslint-disable-next-line
const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const numberRegex = /^[0-9 +().-]+$/;

export const EmailValid = email => email && emailRegexp.test(email);

export const NumberValid = number =>
  numberRegex.test(number) && number.replace(/[^0-9]/g, "").length >= 8;

export const FieldLengthValid = (field, length) =>
  field && field.trim().length > length;
