import React, { useState } from "react";

export const ServicesContext = React.createContext({
  open: false,
  setOpen: () => {},
  setClose: () => {}
});

const ServicesContextProvider = props => {
  const [servicesOpen, setServicesOpen] = useState(false);

  const handleServicesOpen = () => {
    setServicesOpen(true);
  };

  const handleServicesClose = () => {
    setServicesOpen(false);
  };

  return (
    <ServicesContext.Provider
      value={{
        open: servicesOpen,
        setOpen: handleServicesOpen,
        setClose: handleServicesClose
      }}
    >
      {props.children}
    </ServicesContext.Provider>
  );
};

export default ServicesContextProvider;
