import React, { useState } from "react";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import MUIButton from "@material-ui/core/Button";
import { Helmet } from "react-helmet";

import { Input, Button } from "../../atoms";
import { Header } from "../Header";
import { email, phone, phoneTel } from "../../constants/info";
import {
  EmailValid,
  FieldLengthValid,
  NumberValid,
} from "../../utils/paramValidator";

import "./Reservation.scss";

const successMessage = (
  <DialogContentText
    style={{
      fontSize: "1.5rem",
      color: "#000",
    }}
  >
    We have received your message and would like to thank you for writing to us.
    We will reply as soon as possible.
    <br />
    <br />
    Team Global Cargo Bridge
  </DialogContentText>
);

const failureMessage = (
  <DialogContentText
    style={{
      fontSize: "1.5rem",
      color: "#000",
    }}
  >
    Unfortunately something went wrong. Please contact us 
    via {email} or through other mediums.
    <br />
    <br />
    Team Global Cargo Bridge
  </DialogContentText>
);

const Reservation = () => {
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [fieldStatuses, setFieldStatuses] = useState({
    fullName: true,
    emailAddress: true,
    phoneNumber: true,
  });
  const [selectedDate, setSelectedDate] = React.useState(Date.now);
  const [dateError, setDateError] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const isSmaller = useMediaQuery("(max-width:600px)");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateError = (error) => {
    setDateError(error);
  };

  const handleFieldChange = (fieldName, event) => {
    const value = event.target.value;

    switch (fieldName) {
      case "fullName":
        setFullName(value);
        break;
      case "emailAddress":
        setEmailAddress(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }

    // If the field was invalid in the previous state then check for its validity
    if (!fieldStatuses[fieldName]) {
      setFieldStatuses({
        ...fieldStatuses,
        [fieldName]: isFieldValid(fieldName, value),
      });
    }
  };

  const isFieldValid = (fieldName, value) => {
    switch (fieldName) {
      case "fullName":
        return isFullNameValid(value);
      case "emailAddress":
        return isEmailAddressValid(value);
      case "phoneNumber":
        return isPhoneNumberValid(value);
      default:
        return false;
    }
  };

  const isFullNameValid = (paramFullName) => {
    if (!paramFullName) {
      paramFullName = fullName;
    }

    return FieldLengthValid(paramFullName, 5);
  };

  const isEmailAddressValid = (paramEmail) => {
    if (!paramEmail) {
      paramEmail = emailAddress;
    }

    return EmailValid(paramEmail);
  };

  const isPhoneNumberValid = (paramPhone) => {
    if (!paramPhone) {
      paramPhone = phoneNumber;
    }

    return NumberValid(paramPhone);
  };

  const handleFocusOut = (fieldName, event) => {
    if (fieldName in fieldStatuses) {
      setFieldStatuses({
        ...fieldStatuses,
        [fieldName]: isFieldValid(fieldName, event.target.value),
      });
    }
  };

  const handleSubmitClick = () => {
    if (
      isFullNameValid() &&
      isEmailAddressValid() &&
      isPhoneNumberValid() &&
      !dateError
    ) {
      setSubmitLoading(true);

      axios
        .post(`${process.env.REACT_APP_API_URL}`, {
          name: fullName,
          email: emailAddress,
          phone: phoneNumber,
          time: selectedDate,
          message: message,
        })
        .then((result) => {
          setRequestSuccessful(true);
          setSubmitLoading(false);
          setDialogOpen(true);

          setTimeout(function () {
            setDialogOpen(false);
          }, 6000);

          setFullName("");
          setEmailAddress("");
          setPhoneNumber("");
          setMessage("");
        })
        .catch((error) => {
          setRequestSuccessful(false);
          setSubmitLoading(false);
          setDialogOpen(true);
        });
    } else {
      setFieldStatuses({
        fullName: isFullNameValid(),
        emailAddress: isEmailAddressValid(),
        phoneNumber: isPhoneNumberValid(),
      });
    }
  };

  return (
    <div className="Reservation" id="reservation">
      <Helmet>
        <title>Contact Us | Global Cargo Bridge</title>
        <meta
          name="description"
          content="Bridging The World Together"
        />
      </Helmet>
      <Header backgroundColor="#000" menuColor="#000" filter={false} />
      <div className="Reservation__Wrapper">
        <div className="Reservation__Wrapper__Content">
          <h2 className="Reservation__Wrapper__Content__Title">
            Contact Us
          </h2>
          <div className="Reservation__Wrapper__Content__Contact">
            We are always happy to answer your questions
            <br/><br/>
          </div>
          <div className="Reservation__Wrapper__Content__Contact">
            Call{" "}
            <a
              className="Reservation__Wrapper__Content__Contact__Link"
              href={"tel:" + phoneTel}
            >
              {phone}
            </a>
            , Email{" "}
            <a
              className="Reservation__Wrapper__Content__Contact__Link"
              href={"mailto:" + email}
            >
              {email}
            </a>
          </div>
          <h2 className="Reservation__Wrapper__Content__Subtitle">
            Or complete the form and we will get in touch with you.
          </h2>
          <form className="Reservation__Wrapper__Content__Form">
            <Input
              label="Your Name *"
              value={fullName}
              onChange={(event) => handleFieldChange("fullName", event)}
              onBlur={(event) => handleFocusOut("fullName", event)}
              error={!fieldStatuses.fullName}
              helperText={
                !fieldStatuses.fullName && "Please enter your full name"
              }
            />
            <div className="Reservation__Wrapper__Content__Form__Row">
              <Input
                label="Email Address *"
                value={emailAddress}
                onChange={(event) => handleFieldChange("emailAddress", event)}
                onBlur={(event) => handleFocusOut("emailAddress", event)}
                error={!fieldStatuses.emailAddress}
                helperText={
                  !fieldStatuses.emailAddress &&
                  "Please enter your email address"
                }
                marginRight={!isSmaller && 32}
                marginTop={16}
              />
              <Input
                label="Phone Number *"
                value={phoneNumber}
                onChange={(event) => handleFieldChange("phoneNumber", event)}
                onBlur={(event) => handleFocusOut("phoneNumber", event)}
                error={!fieldStatuses.phoneNumber}
                helperText={
                  !fieldStatuses.phoneNumber && "Please enter your phone number"
                }
                marginTop={16}
              />
            </div>
            {/* <div className="Reservation__Wrapper__Content__Form__Row">
              <Input
                label="Date"
                type={1}
                value={selectedDate}
                onChange={handleDateChange}
                onError={handleDateError}
                marginRight={!isSmaller && 32}
                marginTop={16}
              />
              <Input
                label="Time"
                type={2}
                value={selectedDate}
                onChange={handleDateChange}
                marginTop={!isSmaller ? 16 : 10}
              />
            </div> */}
            <Input
              label="Message"
              multiline={true}
              value={message}
              onChange={(event) => handleFieldChange("message", event)}
              marginTop={10}
            />
            <Button
              label="Get In Touch"
              width="270px"
              margin="30px 0 0 0"
              onClick={handleSubmitClick}
              loading={submitLoading}
            />
          </form>
        </div>
      </div>
      <Dialog className="Reservation__Dialog" open={dialogOpen}>
        <DialogTitle>
          <Typography className="Reservation__Dialog__Title">
            {requestSuccessful ? `Thank you ${fullName}` : "Ooops 😕"}
          </Typography>
        </DialogTitle>
        <DialogContent className="Reservation__Dialog__Content">
          {requestSuccessful ? successMessage : failureMessage}
        </DialogContent>
        {!requestSuccessful && (
          <DialogActions>
            <MUIButton
              className="Reservation__Dialog__CloseButton"
              onClick={() => setDialogOpen(false)}
            >
              <Typography className="Reservation__Dialog__CloseButton__Text">
                Close
              </Typography>
            </MUIButton>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default Reservation;
