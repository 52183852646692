import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { scroller } from "react-scroll";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { email, phone, phoneTel } from "../../constants/info";
import { ServicesContext } from "../../contexts/servicesContext";

import "./Footer.scss";

import inappLogo from "../../assets/images/Footer/inapp.png";

const Footer = () => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:768px)");
  const servicesContext = useContext(ServicesContext);
  const { pathname } = window.location;
  const currentYear = new Date().getFullYear();

  const handleLogoClick = () => {
    servicesContext.setClose();
    history.push("/");
  };

  const handleServicesClick = () => {
    if (pathname === "/") {
      servicesContext.setOpen();
    } else {
      history.push({ pathname: "/", state: { openServices: true } });
    }

    if (isMobile) {
      scroller.scrollTo("services", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  const handleReservationClick = () => {
    servicesContext.setClose();
    history.push("/free-quote");
  };

  const handleFleetClick = () => {
    servicesContext.setClose();
    history.push("/our-services");
  };

  const handleAboutClick = () => {
    servicesContext.setClose();
    history.push("/about");
  };

  const handlePrivacyPolicyClick = () => {
    history.push("/privacy-policy");
  };

  return (
    <footer className="Footer">
      <div className="Footer__Wrapper">
        <div className="Footer__Wrapper__Content">
          <div className="Footer__Wrapper__Content__Info">
            <ScrollLink to="landing-page" duration={700} smooth={true}>
              <h3
                className="Footer__Wrapper__Content__Info__BigTitle"
                onClick={handleLogoClick}
              >
                Global Cargo Bridge
              </h3>
            </ScrollLink>
            <p className="Footer__Wrapper__Content__Info__Text">
              Bridging the World Together
            </p>
          </div>

          <div className="Footer__Wrapper__Content__Info">
            <h3 className="Footer__Wrapper__Content__Info__Title">Explore</h3>
            <ScrollLink to="landing-page" duration={700} smooth={true}>
              <p
                className="Footer__Wrapper__Content__Info__LinkText"
                onClick={handleLogoClick}
              >
                Home
              </p>
            </ScrollLink>
            <ScrollLink to="about" duration={500} smooth={true}>
              <p
                className="Footer__Wrapper__Content__Info__LinkText"
                onClick={handleAboutClick}
              >
                About Us
              </p>
            </ScrollLink>
            <ScrollLink to="fleet" duration={500} smooth={true}>
              <p
                className="Footer__Wrapper__Content__Info__LinkText"
                onClick={handleFleetClick}
              >
                Our Services
              </p>
            </ScrollLink>
            <ScrollLink to="services" duration={500} smooth={true}>
              <p
                className="Footer__Wrapper__Content__Info__LinkText"
                onClick={handleServicesClick}
              >
                Additional Services
              </p>
            </ScrollLink>
            <ScrollLink to="reservation" duration={500} smooth={true}>
              <p
                className="Footer__Wrapper__Content__Info__LinkText"
                onClick={handleReservationClick}
              >
                Contact Us
              </p>
            </ScrollLink>
          </div>
          <div className="Footer__Wrapper__Content__Info">
            <h3 className="Footer__Wrapper__Content__Info__Title">Location</h3>
            <p className="Footer__Wrapper__Content__Info__Text">
              8/13 H. Nersisyan Street
            </p>
            <p className="Footer__Wrapper__Content__Info__Text">
              Yerevan, Armenia
            </p>
          </div>
          {/* <div className="Footer__Wrapper__Content__Info">
            <h3 className="Footer__Wrapper__Content__Info__Title">Follow</h3>
            <a
              className="Footer__Wrapper__Content__Info__LinkText"
              href="https://www.instagram.com/zlslimo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              className="Footer__Wrapper__Content__Info__LinkText"
              href="https://www.facebook.com/zlslimo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              className="Footer__Wrapper__Content__Info__LinkText"
              href="https://www.youtube.com/zlslimo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Youtube
            </a>
          </div> */}
          {/* <div className="Footer__Wrapper__Content__Info">
            <h3 className="Footer__Wrapper__Content__Info__Title">Legal</h3>
            <a
              className="Footer__Wrapper__Content__Info__LinkText"
              href="https://www.termsfeed.com/live/e30c1796-7d50-4fd1-817a-1ae24cfb533c"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
            <p
              className="Footer__Wrapper__Content__Info__LinkText"
              onClick={handlePrivacyPolicyClick}
            >
              Privacy Policy
            </p>
          </div> */}
          <div className="Footer__Wrapper__Content__Info">
            <h3 className="Footer__Wrapper__Content__Info__Title">Contacts</h3>
            <a
              className="Footer__Wrapper__Content__Info__LinkText"
              href={"mailto:" + email}
            >
              {email}{" "}
            </a>
            <a
              className="Footer__Wrapper__Content__Info__LinkText"
              href={"tel:" + phoneTel}
            >
              {phone}
            </a>
          </div>
        </div>
        <div className="Footer__Wrapper__Copyright">
          <p className="Footer__Wrapper__Copyright__Text">
            © {currentYear} Global Cargo Bridge. All Rights Reserved.
          </p>
          <div
            className="Footer__Wrapper__Copyright__Developed"
            style={{ marginTop: 8 }}
          >
            Developed By&nbsp;
            <a
              className="Footer__Wrapper__Copyright__Developed__Link"
              href="https://www.inappsystems.com?location=zlslimo"
              target="_blank"
              rel="noopener noreferrer"
            >
              InApp Systems&nbsp;
              <img
                src={inappLogo}
                alt="InApp Systems"
                className="Footer__Wrapper__Copyright__Developed__Link__Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
