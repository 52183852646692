import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { ServicesContext } from "../../contexts/servicesContext";

import "./Header.scss";

import logo from "../../assets/images/LandingPage/logo.png";

const Header = ({
  backgroundColor,
  bright = false,
  noHeader = false,
  drawerOpen = false,
  menuColor = "#FFF",
  filter = true,
}) => {
  const [drawerOpenState, setDrawerOpenState] = useState(drawerOpen);

  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:768px)");
  const servicesContext = useContext(ServicesContext);
  const { pathname } = window.location;

  const handleHomeClick = () => {
    servicesContext.setClose();
    history.push("/");
  };

  const handleServicesClick = () => {
    if (isMobile) {
      scroller.scrollTo("services", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }

    if (pathname === "/") {
      servicesContext.setOpen();
    } else {
      history.push({ pathname: "/", state: { openServices: true } });
    }
  };

  const handleAboutClick = () => {
    servicesContext.setClose();
    history.push("/about");
  };

  const handleFleetClick = () => {
    servicesContext.setClose();
    history.push("/our-services");
  };

  const handleReserveClick = () => {
    servicesContext.setClose();
    history.push("/free-quote");
  };

  const toggleDrawer = (open, event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpenState(open);
  };

  const getMobileHeaderItem = (title, onClick) => (
    <>
      <ListItem button onClick={onClick}>
        <ListItemText
          primary={
            <p className="Header__Drawer__List__ListItemText">{title}</p>
          }
        />
      </ListItem>
      {/* <Divider className="Header__Drawer__List__Divider" /> */}
    </>
  );

  const drawerVisuals = (
    <div
      className="Header__Drawer"
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List className="Header__Drawer__List">
        <ListItem className="Header__Drawer__List__ListTitle">
          <ListItemIcon
            onClick={handleHomeClick}
          >
            <div className="Header__Drawer__List__ListTitle__Wrapper">
              <img
                src={logo}
                alt="Global Cargo Bridge"
                className="Header__Drawer__List__ListTitle__Wrapper__Logo"
              />
            </div>
          </ListItemIcon>
        </ListItem>

        {getMobileHeaderItem("About Us", handleAboutClick)}
        {getMobileHeaderItem("Our Services", handleFleetClick)}
        {getMobileHeaderItem("Additional Services", handleServicesClick)}
        {getMobileHeaderItem("Contact Us", handleReserveClick)}
      </List>
    </div>
  );

  const getHeaderItem = (title, onClick) => (
    <h2
      className={`Header__Wrapper__Actions__PageName${bright ? "-Bright" : ""}`}
      onClick={onClick}
    >
      {title}
    </h2>
  );

  return (
    <>
      <header
        className="Header"
        style={{ height: noHeader && !drawerOpen && 0 }}
      >
        {isMobile || noHeader ? (
          <div className="Header__MenuWrapper">
            {!(!isMobile && noHeader) && (
              <div
                className="Header__MenuWrapper__LogoWrapper"
                onClick={handleHomeClick}
              >
                <img
                  src={logo}
                  alt="Global Cargo Bridge"
                  className="Header__MenuWrapper__LogoWrapper__Logo"
                  style={{ filter: !filter && "none" }}
                />
              </div>
            )}
            {!(!isMobile && noHeader) && (
              <IconButton
                className="Header__MenuWrapper__MenuButton"
                onClick={() => toggleDrawer(true)}
                style={{ display: drawerOpenState && "none" }}
              >
                <MenuIcon
                  className="Header__MenuWrapper__MenuButton__Icon"
                  style={{ color: menuColor }}
                />
              </IconButton>
            )}
          </div>
        ) : (
          <>
            <div className="Header__Background"></div>
            <div
              className="Header__Wrapper"
              style={{
                backgroundColor: backgroundColor,
                borderBottom: bright
                  ? "1px solid #00000003"
                  : "1px solid #FFFFFF1C",
              }}
            >
              <div
                className="Header__Wrapper__LogoWrapper"
                onClick={handleHomeClick}
              >
                <img
                  src={logo}
                  alt="Global Cargo Bridge"
                  className="Header__Wrapper__LogoWrapper__Logo"
                />
              </div>
              <div className="Header__Wrapper__Actions">
                {getHeaderItem("About Us", handleAboutClick)}
                {getHeaderItem("Our Services", handleFleetClick)}
                {getHeaderItem("Additional Services", handleServicesClick)}
                {getHeaderItem("Contact Us", handleReserveClick)}
              </div>
            </div>
          </>
        )}
      </header>
      <SwipeableDrawer
        variant={!isMobile && noHeader ? "permanent" : "temporary"}
        PaperProps={{ className: "Header__DrawerParent" }}
        open={drawerOpenState}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {drawerVisuals}
      </SwipeableDrawer>
    </>
  );
};

export default Header;
