import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";

import "./Input.scss";

const inputTheme = createMuiTheme({
  typography: {
    htmlFontSize: 10
  },
  h2: {},
  palette: {
    primary: {
      main: "#000"
    }
  }
});

// Type 0 - Simple Input
// Type 1 - Date
// Type 2 - Time

const Input = ({
  label,
  multiline,
  marginTop,
  marginRight,
  type = 0,
  ...inputProps
}) => {
  const [isHover, setIsHover] = useState(false);

  const getInput = () => {
    switch (type) {
      case 0:
        return (
          <TextField
            multiline={multiline}
            rowsMax={multiline && 7}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            inputProps={{
              maxLength: !multiline ? 40 : 1024,
              "aria-label": "name"
            }}
            InputProps={{
              className: "CustomInputField__InputText"
            }}
            InputLabelProps={{
              className: "CustomInputField__InputText"
            }}
            FormHelperTextProps={{
              className: "CustomInputField__InputHelper"
            }}
            {...inputProps}
          />
        );
      case 1:
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            className="Reservation__Wrapper__Content__Form__Row"
          >
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              disablePast
              id="date-picker-inline"
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              InputProps={{
                className: "CustomInputField__InputText"
              }}
              InputLabelProps={{
                className: "CustomInputField__InputText"
              }}
              FormHelperTextProps={{
                className: "CustomInputField__InputHelper"
              }}
              {...inputProps}
            />
          </MuiPickersUtilsProvider>
        );
      case 2:
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            className="Reservation__Wrapper__Content__Form__Row"
          >
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
              InputProps={{
                className: "CustomInputField__InputText",
                step: 300 // 5 min
              }}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              InputLabelProps={{
                className: "CustomInputField__InputText"
              }}
              FormHelperTextProps={{
                className: "CustomInputField__InputHelper"
              }}
              {...inputProps}
            />
          </MuiPickersUtilsProvider>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="CustomInputField"
      style={{ marginTop: marginTop, marginRight: marginRight }}
    >
      <label
        className="CustomInputField__Label"
        style={{
          marginLeft: isHover && "8px",
          marginBottom: type === 0 ? 10 : -4
        }}
      >
        {label}
      </label>
      <ThemeProvider theme={inputTheme}>{getInput()}</ThemeProvider>
    </div>
  );
};

export default Input;
