import React, { useEffect, useContext } from "react";
import { scroller } from "react-scroll";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ServicesContext } from "../../contexts/servicesContext";
import { LandingPage } from "../LandingPage";
import { Services } from "../Services";
import Steps from "../Steps";
//import { Quotes } from "../Quotes";

const Home = (props) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const servicesContext = useContext(ServicesContext);

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.openServices
    ) {
      if (isMobile) {
        scroller.scrollTo("services", {
          duration: 800,
          delay: 200,
          smooth: "easeInOutQuart",
        });
      } else {
        servicesContext.setOpen();
      }
    }

    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <>
      <LandingPage />
      <Services />
      <Steps />
    </>
  );
};

export default Home;
