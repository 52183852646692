import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { phone, phoneTel } from "../../constants/info";

import "./Steps.scss";

const Steps = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="Steps">
      <div className="Steps__Wrapper">
        <div className="Steps__Wrapper__Empty"></div>
        <h4 className="Steps__Wrapper__Title">Services Made Simple</h4>
        <div className="Steps__Wrapper__Main">
          <div className="Steps__Wrapper__Main__Step">
            <p className="Steps__Wrapper__Main__Step__Number">1</p>
            <p className="Steps__Wrapper__Main__Step__Text">Call Our Team</p>
          </div>
          <div className="Steps__Wrapper__Main__Step">
            <p className="Steps__Wrapper__Main__Step__Number">2</p>
            <p className="Steps__Wrapper__Main__Step__Text">
              Customize Your Experience
            </p>
          </div>
          <div className="Steps__Wrapper__Main__Step">
            <p className="Steps__Wrapper__Main__Step__Number">3</p>
            <p className="Steps__Wrapper__Main__Step__Text">
              Book a Service
            </p>
          </div>
        </div>
        <a className="Steps__Wrapper__CallButton" href={"tel:" + phoneTel}>
          {!isMobile ? (
            <>Contact Us {phone}</>
          ) : (
            <>
              Contact Us <br /> {phone}
            </>
          )}
        </a>
      </div>
    </div>
  );
};

export default Steps;
