import React from "react";

import airFreight from "../../assets/images/Fleet/air-freight.jpg";

import oceanFreight from "../../assets/images/Fleet/ocean-freight.jpg";

import groundFreight from "../../assets/images/Fleet/ground-freight.jpg";

import projectsCargoLogistics from "../../assets/images/Fleet/projects-cargo-logistics.JPG";

const allCars = [
  {
    name: "Air Freight",
    images: [airFreight],
    description: (
      <div>
        <p>General Cargo Services, Air Premium Services, Air Charter Services, Intermodal Solutions</p>
      </div>
    )
  },
  {
    name: "Ocean Freight",
    images: [oceanFreight],
    description: (
      <div>
        <p>FCL, LCL, Bulk Cargo, Charter Services, Intermodal Solutions</p>
      </div>
    )
  },
  {
    name: "Ground Freight",
    images: [groundFreight],
    description: (
      <div>
        <p>Road Freight, Rail Freight, Multimodal Solutions</p>
      </div>
    )
  },
  {
    name: "Project Cargo Logistics ",
    images: [projectsCargoLogistics],
    description: (
      <div>
        <p>Break Bulk, Heavy lifts</p>
      </div>
    )
  },
];

export default allCars;
