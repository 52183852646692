import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Helmet } from "react-helmet";

import { Header } from "../Header";
import { Arrow } from "../../atoms";
import { FleetItem } from "./FleetItem";
import allCars from "./allCars";

import "./Fleet.scss";

const Fleet = () => {
  return (
    <div className="Fleet">
      <div className="Fleet__LandingPage" id="fleet">
        <Helmet>
          <title>Our Services | Global Cargo Bridge</title>
          <meta
            name="description"
            content="Bridging The World Together"
          />
        </Helmet>
        <Header backgroundColor="#000" menuColor="#000" filter={false} />
        <div className="Fleet__LandingPage__SideWrapper">
          <h2 className="Fleet__LandingPage__SideWrapper__SecondaryText">
            Our Services
          </h2>
          <ScrollLink to="fleet-items" duration={500} smooth={true}>
            <Arrow />
          </ScrollLink>
        </div>
      </div>
      <div className="Fleet__Wrapper" id="fleet-items">
        {allCars.map((car, index) => (
          <FleetItem
            key={car.name}
            name={car.name}
            images={car.images}
            description={car.description}
            reverse={index % 2 === 1}
          />
        ))}
      </div>
    </div>
  );
};

export default Fleet;
