import React from "react";

import "./Arrow.scss";

const Arrow = ({ color = "white" }) => {
  return (
    <div className="Arrow">
      <div className="Arrow__Icon" style={{ borderColor: color }}></div>
    </div>
  );
};

export default Arrow;
