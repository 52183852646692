import React, { useState } from "react";
import ReactImageMagnify from "react-image-magnify";
//import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./FleetItem.scss";

const widthHeightRatio = 0.65;

const FleetItem = ({ name, images, description, reverse = false }) => {
  //const isMobile = useMediaQuery("(max-width:768px)");
  // eslint-disable-next-line
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  // eslint-disable-next-line
  const [mainImageHover, setMainImageHover] = useState(false);

  // const handleImageSelect = index => {
  //   setSelectedImageIndex(index);
  // };

  const getMainImageSize = scale => {
    let width =
      window.innerWidth * scale < 360 ? 360 : window.innerWidth * scale;

    width = width > 640 ? 640 : width;

    return { width: width, height: width * widthHeightRatio };
  };

  // const handleMainImageHoverEnter = () => {
  //   if (!isMobile) {
  //     setTimeout(() => {
  //       setMainImageHover(true);
  //     }, 300);
  //   }
  // };

  // const handleMainImageHoverLeave = () => {
  //   if (!isMobile) {
  //     setTimeout(() => {
  //       setMainImageHover(false);
  //     }, 300);
  //   }
  // };

  return (
    <div className="FleetItem">
      {/* <h2 className="FleetItem__Title">{name}</h2> */}
      <div
        className="FleetItem__Content"
        style={{ flexDirection: reverse && "row-reverse" }}
      >
        <div className="FleetItem__Content__Images">
          <div
            id="main-image"
            // onMouseEnter={handleMainImageHoverEnter}
            // onMouseLeave={handleMainImageHoverLeave}
          >
            <ReactImageMagnify
              style={{ zIndex: 10, cursor: "unset" }}
              enlargedImagePosition="over"
              imageClassName={
                !mainImageHover
                  ? "FleetItem__Content__Images__MainImage"
                  : "FleetItem__Content__Images__MainImage-Hover"
              }
              enlargedImageClassName="FleetItem__Content__Images__MainImage"
              smallImage={{
                src: images[selectedImageIndex],
                alt: name,
                width: getMainImageSize(0.34).width,
                height: getMainImageSize(0.34).height
              }}
              largeImage={{
                src: images[selectedImageIndex],
                alt: name,
                width: getMainImageSize(0.34).width,
                height: getMainImageSize(0.34).height
              }}
            />
          </div>

          {/* <div className="FleetItem__Content__Images__MoreImages">
            {images.map((image, index) => (
              <div
                key={index}
                className={
                  selectedImageIndex !== index
                    ? "FleetItem__Content__Images__MoreImages__Container"
                    : "FleetItem__Content__Images__MoreImages__Container--active"
                }
                onMouseEnter={() => handleImageSelect(index)}
              >
                <img
                  className="FleetItem__Content__Images__MoreImages__Container__Image"
                  src={image}
                  alt={name}
                />
              </div>
            ))}
          </div> */}
        </div>
        <div
          className="FleetItem__Content__TextWrapper"
          style={{ margin: !reverse ? "0 0 0 40px" : "0 40px 0 0" }}
        >
          <h2 className="FleetItem__Content__TextWrapper__Title">{name}</h2>
          <div className="FleetItem__Content__TextWrapper__Text">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetItem;
